// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { apiRequest } from "../../../apiRquest/apiRequest";
import SlickBeneficios from "../../../components/fondos_beneficios/fondos_beneficios";
import DetailList from "../../../components/detail_list/detail_list";
import DataTable from "../../../components/data_table/data_table";
import HorizontalBarChart from "../../../components/horizontal_bar_chart/horizontal_bar_chart";
import LineChart from "../../../components/line_chart/line_chart";
import LogoFCI from "../../../imgs/logos-fci/Logo_FCI_PERFIII.svg";
import FondosNav from "../../../components/fondos_nav/fondos_nav"
import ProductBanner from "../../../components/product_banners/product_banners";
import Banner from "../../../imgs/bg_productos.jpg";
import Button from "../../../components/buttons/button";
import "./perfomance-3.css"
import IframeVIdeo from "../../../components/iframe_video/iframe_video";
import Loading from "../../../components/loading/loading"
import Calificacion from "../../../imgs/docs/calificacion-fix.svg";
import Cuentas from "../../../imgs/docs/cuentas-bancarias.svg";
import Factsheet from "../../../imgs/docs/factsheet.svg";
import Informe from "../../../imgs/docs/informe-riesgo-trimestral.svg";
import Reglamento from "../../../imgs/docs/reglamento-gestion.svg";
import Impositivo from "../../../imgs/docs/tratamiento-impositivo.svg";
import SlickDocs from "../../../components/fondos_docs/fondos_docs";

const pageTitle = 'Fondo Común de Inversión Balanz Performance III Corporativo ';
const pageDesc = 'Tus ahorros pueden rendir más. Invertí en este Fondo de Inversión y cambiá tu forma de ahorrar.';


// Step 2: Define your component
const PerformanceIII = () => {
    //const chart = useRef(null);
    const [detail, setDetail] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [detail2, setDetail2] = useState([]);
    const [stadistics, setStadistics] = useState([]);
    const [cartera, setCartera] = useState([]);
    const [fechaCartera, setFechaCartera] = useState('');
    const [loadingCartera, setLoadingCartera] = useState(false);
    const [variations, setVariations] = useState({});
    const [evolucionCuotaparte, setEvolucionCuotaparte] = useState([]);
    const [deeplinkData, setDeeplinkData] = useState([]);
    const [loadingCuotaparte, setLoadingCuotaparte] = useState(false);
    const [rentabilidades, setRentabilidades] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [xs, setXs] = useState(false);

    const nameFCI = 'Fondo Balanz <br /><b>Performance III Corporativo </b>';
    const nameFCIStrip = nameFCI.replace(/<\/?[^>]+(>|$)/g, "");

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);


    useEffect(() => {
        setLoadingDetails(true);
        setLoadingCartera(true);
        setLoadingCuotaparte(true);

        apiRequest
            .get("/v1/funds/funds_data")
            .then((res) => {
                let customData = res.data;
                let deeplink = customData.filter(el => el.CodFondo == 37);
                if(deeplink){
                    setDeeplinkData(deeplink[0].deeplink)
                }else {
                    setDeeplinkData('https://clientes.balanz.com/')
                }
                //console.log(customData);
            })
            .catch((err) => {
                // console.log(err);
            })


        apiRequest
            .post("/v1/funds/yields",{ id: 37})
            .then((res) => {
                /* let dataSet = [];*/
                let fullData = res.data;
                //console.log("res.data", res.data)
                let filteredData =  fullData.filter(element => element.idFondo === 37 && element.idI === 250);
                if (filteredData.length > 0){
                    const el = filteredData[0];
                    setDetail([
                        { title: "Inicio", value: el.fechaInicio },
                        { title: "Moneda", value: el.idMoneda === 1 ? "Pesos" : (el.idMoneda === 2 ? "Dólares" : "-")},
                        { title: "Perfil de Inversor", value: "" },
                        { title: "Horizonte", value: "" },
                        { title: "Tipo de Fondo", value: "" },
                        { title: "Inversión Mínima", value: "" },
                        { title: "Patrimonio", value: `${new Intl.NumberFormat('es-AR', { style: 'currency',maximumFractionDigits: 0, minimumFractionDigits: 0,  currency: 'ARS' }).format(el.patrimonio)}` },
                        { title: "Plazo de Rescate", value: "" },
                        { title: "Ticker", value: "" },
                        { title: "Ticker Bloomberg", value: "" },
                        { title: "Calificación Fix SCR", value: "" },
                    ]);

                    setVariations({
                        headers: [
                            "",
                            "Valor cuota",
                            "Variación Diaria",
                            "Variación YTD",
                            "Variación 1&nbsp;Mes",
                            "Variación 3&nbspMeses",
                            "Variación 6&nbspMeses",
                            "Variación 12&nbspMeses",
                            "Inicio Operaciones",
                        ],
                        dataSet: [
                            [
                                nameFCIStrip,
                                `${(el?.cotizacion !==null)? el.cotizacion : '-'}`,
                                `${(el?.varDiaria !==null)? el.varDiaria+'%' : '-'}`,
                                `${(el?.varYTD !==null)? el.varYTD+'%' : '-'}`,
                                `${(el?.varMensual !==null)? el.varMensual+'%' : '-'}`,
                                `${(el?.var3Meses !==null)? el.var3Meses+'%' : '-'}`,
                                `${(el?.var6Meses !==null)?el.var6Meses+'%' : '-'}`,
                                `${(el?.varAnual !==null)? el.varAnual+'%' : '-' }`,
                                `${(el?.rendimientoDesdeInicio !==null)? el.rendimientoDesdeInicio+'%' : '-'}`
                            ],
                        ],
                    });

                    setStadistics([
                        {
                            title: "Rendimiento desde el inicio",
                            value: `${el.rendimientoDesdeInicio}%`,
                        },
                        {
                            title: "Meses Positivos",
                            value: Number(el.mesesPositivos),
                        },
                        {
                            title: "Meses Negativos",
                            value: Number(el.mesesNegativos),
                        },
                        {
                            title: "Mejor Rendimiento Diario",
                            value: `${el.mejorRendimientoDiario}%`,
                        },
                        {
                            title: "Peor Rendimiento Diario",
                            value: `${el.peorRendimientoDiario}%`,
                        },
                        {
                            title: "Rendimiento Promedio Diario",
                            value: `${el.rendimientoPromedioDiario}%`,
                        },
                        {
                            title: "Volatilidad (21d) anualizada",
                            value: `${el.volatilidad21d}%`,
                        },
                    ]);

                    let rent = el.rentabilidadMensualAnualizadaPorMes
                    if(rent.length > 0){

                        let rentData =[];
                        let years =[];
                        let year = '';
                        let i = -1
                        rent.forEach((row, index) => {
                            if(year != row.anio) {
                                year = row.anio
                                i++
                                years[i]= year;
                                if(rentData[i]==undefined){
                                    rentData[i]=[];
                                }
                                rentData[i].push(year);
                            }

                            if(index == 0) {
                                let mesInicio = row.mes;
                                let diff = mesInicio - 1
                                if(diff > 0){
                                    for(let j = 0; j < diff; j++){
                                        rentData[i].push('-')
                                    }
                                }
                            }

                            rentData[i].push(row.rentabilidadMensual + '%');

                            //console.log((index+1),rent[(index+1)])

                            if(rent[(index+1)] == undefined){
                                let passedMonths = rent[index].mes
                                let leftMonths = 12 - passedMonths
                                //console.log('passedMonths', passedMonths)
                                //console.log('leftMonths', leftMonths)

                                if(leftMonths >= 0){
                                    //let end = (leftMonths - 1)
                                    for( let k = 0; k <= leftMonths; k++){
                                        //console.log('k',k)
                                        if(k == leftMonths){
                                            let total = rent[index].rentabilidadAnual+'%'
                                            rentData[i].push(total)
                                        }else{
                                            rentData[i].push('-')
                                        }
                                    }
                                }

                            } else if(rent[(index+1)].anio != year){
                                /*console.log('cambio año')*/
                                let total = rent[index].rentabilidadAnual+'%'
                                rentData[i].push(total);

                            }

                        })

                        setRentabilidades({
                            headers: [
                                nameFCI,
                                "Ene",
                                "Feb",
                                "Mar",
                                "Abr",
                                "May",
                                "Jun",
                                "Jul",
                                "Ago",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dic",
                                "Total",
                            ],
                            dataSet: rentData
                        });
                    }else{
                        // console.log('dato vacio rentabilidad mensual anualizada')
                    }


                }else{
                    // console.log('dato vacio fondo')
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setLoadingDetails(false);
        });

        apiRequest
            .post("/v1/funds/history",
                { id: 37})
            .then((res) => {
                let dataSet = [];
                res.data.forEach((element) => {
                    dataSet.push({ date: element.Fecha, value: element.ValorCuotaparte });
                });
                setEvolucionCuotaparte(dataSet);
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setLoadingCuotaparte(false);
        })

        apiRequest
            .post("/v1/funds/information")
            .then((res) => {
                let dataSet = [];
                let dataSet2 = [];
                let fullData = res.data;
                //console.log("res.data", res.data)
                let filteredData =  fullData.filter(element => element.CodFondo === 37);
                if (filteredData.length > 0){
                    const element = filteredData[0];
                    setFechaCartera (element.ComposicionCarteraFecha);
                    setDetail2([
                        { title: "Perfil de Inversor", value: element.PerfilInversor || '-'  },
                        { title: "Tipo de Fondo", value: element.TipoFondo  || '-'},
                        { title: "Horizonte", value: element.Horizonte  || '-'},
                        { title: "Inversión Mínima", value: `${new Intl.NumberFormat('es-AR', { style: 'currency', maximumFractionDigits: 0, minimumFractionDigits: 0,  currency: 'ARS' }).format(element.InversionMinima)}` || '-'},
                        { title: "Plazo de Rescate", value: element.PlazoRescate || '-' },
                        { title: "Calificación Fix SCR", value: element.Calificacion || '-' },
                        { title: "Ticker", value: element.NombreAbreviado || '-' },
                        { title: "Ticker Bloomberg", value: element.TickerBloomberg || '-' },
                    ]);
                    if(filteredData[0].ComposicionCartera.length > 0){
                        filteredData[0].ComposicionCartera.forEach((item) => {
                            dataSet.push({ name: item.Nombre, value: item.Valor });
                        });

                        setCartera(dataSet);
                    }else{
                        // console.log('dato vacio')
                    }

                    if(filteredData[0].Documentos.length > 0){
                        filteredData[0].Documentos.forEach((item) => {
                            dataSet2.push({ name: item.Nombre, value: item.Url });
                        });
                        setDocumentos(dataSet2);
                    }else{
                        // console.log('dato vacio')
                    }

                }else{
                    // console.log('dato vacio')
                }

            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setLoadingCartera(false);
        })

        return () => {};
    }, []);

    let detailsTest = [
        { title: "Rendimiento desde el inicio", value: "-" },
        { title: "Dias Positivos", value: "-" },
        { title: "Dias Negativos", value: "-" },
        { title: "Mejor Rendimiento Diario", value: "-" },
        { title: "Peor Rendimiento Diario", value: "-" },
        { title: "Rendimiento Promedio Diario", value: "-" },
        { title: "Volatilidad (21d) anualizada", value: "-" },
    ];

    let headersTest = ["","Valor cuota",
        "Variación Diaria",
        "Variación YTD",
        "Variación 1&nbsp;Mes",
        "Variación 3&nbspMeses",
        "Variación 6&nbspMeses",
        "Variación 12&nbspMeses",
        "Inicio Operaciones",];
    let valuesTest = [
        [nameFCIStrip, "-", "-", "-", "-", "-", "-", "-"]
    ];

    let lineChartData = [];
    let value = 50;
    for (let i = 0; i < 300; i++) {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(i);
        value -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        lineChartData.push({ date: date, value: value });
    }

    let docsArr = [];
    const baseUrl = `${process.env.GATSBY_BASE_URL}`;
    documentos.forEach((element, index) => {
        let iconDoc = '';
        let nameDoc = '';
        let doc = {};
        switch (element.name){
            case "Informe de Riesgo Trimestral":
            case "Informe Trimestral":
            case "Informe trimestral de riesgos":
                doc.iconDoc = Informe;
                doc.nameDoc = "Informe de Riesgo Trimestral";
                break;
            case "Reglamento de Gestion":
                doc.iconDoc = Reglamento;
                doc.nameDoc = "Reglamento de Gestión";
                break;
            case "Calificación FIX":
                doc.iconDoc = Calificacion;
                doc.nameDoc = "Calificación FIX";
                break;
            case "Informe Semanal":
            case "Factsheet":
            case "Factsheet Español":
            case "Factsheet en Español":
            case "Factsheet en español":
                doc.iconDoc = Factsheet;
                doc.nameDoc = "Factsheet en Español";
                break;

            case "Factsheet (Inglés)":
            case "Factsheet en ingles":
            case "Factsheet en inglés":
            case "Factsheet en Inglés":
                doc.iconDoc = Factsheet;
                doc.nameDoc = "Factsheet en Inglés";
                break;

            case "Cuentas Bancarias para Suscribir":
                doc.iconDoc = Cuentas;
                doc.nameDoc = "Cuentas Bancarias";
                break;

            case "Tratamiento Impositivo":
            case "Tratamiento impositivo":
                doc.iconDoc = Impositivo;
                doc.nameDoc = "RG 917 (BBPP) Ejercicio 2022";
                break;

            default:
                doc.iconDoc = Calificacion;
                doc.nameDoc = element.name;
        }
        if(element.name.includes('RG 917 (BBPP)')){
            doc.iconDoc = Impositivo;
            if(element.name.includes('RG 917 (BBPP) Ejercicio')){
                doc.nameDoc = element.name.replace('RG 917 (BBPP) Ejercicio', "Resolución 917 BBPP -");
            }else{
                doc.nameDoc = element.name;
            }
        }
        doc.link = element.value;
        docsArr.push(doc)

    });

    const slickDocsSettings = {
        centerMode: false,
        infinite: false,
        arrows: true,
        dots: false,
        initialSlide: 1,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    infinite: false,
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    infinite: false,
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    dots: false,
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    let beneficios=[
        {
            image: "/imgs/beneficios/renta-fija-corporativa.svg",
            title: "Renta Fija Corporativa",
            description: "Cartera diversificada de riesgo corporativo.",
        },
        {
            image: "/imgs/beneficios/administrado-por-expertos.svg",
            title: "Administrado por expertos",
            description: "Profesionales con amplia trayectoria.",
        },
        {
            image: "/imgs/beneficios/transparente.svg",
            title: "Transparencia",
            description: "Consultá diariamente el rendimiento.",
        },
        {
            image: "/imgs/beneficios/diversificacion.svg",
            title: "Diversificación",
            description: "Accedé a distintos activos a través de un solo instrumento."
        }
    ]
    const slickSettings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 4,
        dots: true,
        arrows: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 1920,
                settings: { slidesToShow: 4, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 1400,
                settings: { centerMode: false, slidesToShow: 4, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 991,
                settings: { centerMode: false, slidesToShow: 2, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 767,
                settings: { centerMode: false, slidesToShow: 2, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 575,
                settings: { centerMode: true, slidesToShow: 1, slidesToScroll: 1, infinite: false }
            },
            {
                breakpoint: 416,
                settings: { centerMode: true, slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };
    const formatDate = (date)=>{
        let formatted_date = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
        return formatted_date;
    }

    const page = (
        <div className="d-flex flex-column">
            <ProductBanner
                bannerContainerClassName="bannerFCIMM"
                bannerTrasparecy="bannerMask"
                productTitle={nameFCI}
                productTitleClassName="bbottom-perfomance3"
                bannerImage={Banner}
                bannerImageClassName="accionesBannerImage"
                textContainerClassName="textContainer"
                bannerText={null}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <div className="fondos-nav-holder order-2 order-md-1">
                <Container>
                    <Row className="py-5 py-md-4">
                        <Col xs={12} className="fondos-nav">
                            <FondosNav isXS = {xs} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <section id="Top" className="top-section fullwidth fci-presentacion d-flex flex-column order-1 order-md-2">
                {/*<div className="fondos-nav-holder order-2 order-md-1">
            <Container>
              <Row className="py-5 py-md-4">
                <Col xs={12} className="fondos-nav">
                  <FondosNav isXS = {xs} />
                </Col>
              </Row>
            </Container>
          </div>*/}
                <div className="order-1 order-md-2">
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col xs={12} md={8}>
                                <img src={LogoFCI} alt="FCI" className="img-fluid" />
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <p className="base-text-2 mt-0 mb-3"><strong>Diversificá tu cartera invirtiendo en renta fija corporativa</strong></p>
                            <div className="pt-4 text-center"
                                //  data-sal="fade"
                                //  data-sal-delay="300"
                                //  data-sal-duration="300"
                            >
                                <Button id="fondos_invertir_1" variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjNBIiwicGFuZWwiOiIxMCJ9fQ", "_blank") }} />
                            </div>
                        </Row>
                    </Container>
                </div>
            </section>
            <div id="Información" className="order-3">
                <section className="fullwidth fci pb-0">
                    <Container>
                        <h2 className="main-title">Información</h2>

                        <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <h2 className="mb-0">Objetivo y política de inversión</h2>
                            <Col xs={12} >
                                <p className="base-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                    El objetivo del fondo es maximizar los rendimientos ajustados por riesgo a través de la inversión en un portafolio compuesto por activos de renta fija corporativos.<br /><br />
                                    Ideal para personas físicas y jurídicas que prioricen riesgo corporativo en sus carteras y tengan un horizonte de inversión de mediano plazo.
                                </p>
                            </Col>
                        </Row>
                        {/*<Row className="py-4 py-md-5 justify-content-center">
                <Col xs={12} md={8} className="d-lg-flex justify-content-lg-center">
                  <IframeVIdeo
                      src="https://www.youtube.com/embed/dF6T13LSpog"
                      resolutionClass={null}
                  />
                </Col>
              </Row>*/}
                    <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/fdw8oSs4bTI"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                    </Container>
                </section>
                <section className="fullwidth fci beneficios">
                    <Container>
                        <h2 className="">Ventajas</h2>
                        <Row>
                            <Col xs={12}>
                                <SlickBeneficios
                                    slickSettings={slickSettings}
                                    list={beneficios}
                                    customClass={'beneficiosSlick'}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth fci detalle">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {(loadingCartera  || loadingDetails) ? (
                                    <Loading customClass="loading-cartera position-relative " />
                                ) : (
                                    <DetailList
                                        list={detail}
                                        list2 ={detail2}
                                        title="Detalle del fondo"
                                    />
                                )}
                                {/*<div className="product-cta text-start text-md-center pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                    <Button id="fci_sud_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir" onClick={() => { window.open(`${deeplinkData}`, "_blank") }}  />
                                </div>*/}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div id="Rendimiento" className="order-4">
                <section className="fullwidth fci rendimiento">
                    <Container>
                        <h2 className="main-title" style={{paddingBottom: "0.35em"}}>Rendimiento<br />
                            <span style={{fontWeight: "300", fontSize: "90%"}}>Clase D</span>
                        </h2>
                        <Row className="pb-5">
                            <Col xs={12}>
                                {(!loadingCuotaparte && evolucionCuotaparte.length > 0) ? (
                                    <LineChart
                                        dataSet={evolucionCuotaparte}
                                        title="Evolución Cuotaparte"
                                    />
                                ) : (
                                    <Loading customClass="loading-cuotaparte"/>
                                )
                                }
                            </Col>
                        </Row>
                        <Row className="py-5">
                            <Col xs={12} className="gx-0 px-0">
                                {loadingDetails ? (
                                    <Loading customClass="loading-variations"/>
                                ) : (
                                    <DataTable
                                        customClass = "table-variations"
                                        dataset={variations?.dataSet ? variations.dataSet : valuesTest}
                                        headers={variations?.headers ? variations.headers : headersTest}
                                        title="Variaciones"
                                    />
                                )
                                }
                                <table>

                                </table>
                            </Col>
                        </Row>
                        <Row className="py-5">
                            <Col xs={12}>
                                {loadingDetails ? (
                                    <Loading customClass="loading-stadistics"/>
                                ) : (
                                    <DetailList
                                        list={stadistics.length > 0 ? stadistics : detailsTest}
                                        list2={null}
                                        title="Información Estadística"
                                    />
                                )
                                }
                            </Col>
                        </Row>
                        <Row className="py-5">
                            <Col xs={12}>
                                {loadingDetails ? (
                                    <Loading customClass="loading-rentabilidades"/>
                                ) : (
                                    <DataTable
                                        customClass = "table-rentabilidades"
                                        dataset={rentabilidades.dataSet}
                                        headers={rentabilidades.headers}
                                        title="Rentabilidad Mensual"
                                    />
                                )}
                            </Col>
                        </Row>

                    </Container>
                </section>
            </div>
            <div id="Cartera" className="order-5">
                <section className="fullwidth fci cartera">
                    <Container>
                        <h2 className="main-title">Cartera</h2>
                        <Row>
                            <Col xs={12} className="base-text chart-bar">
                                {loadingCartera ? (
                                    <Loading customClass="loading-cartera" />
                                ) : (
                                    <HorizontalBarChart
                                        dataSet={cartera}
                                        title="Composición de la Cartera"
                                        date={`Datos al ${formatDate(new Date(fechaCartera))}`}
                                        isXS={xs}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div id="Documentos" className="order-6">
                <section className="fullwidth fci documentos">
                    <Container>
                        <h2 className="main-title">Documentos</h2>
                        <Row>
                            <Col xs={12}>
                                <SlickDocs
                                    slickSettings={slickDocsSettings}
                                    list={docsArr}
                                    customClass={'docsSlick'}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"performance2"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default PerformanceIII;
